<template>
    <div class="body fixed mobile"> <!-- class: fixed, mobile -->
        <!-- <label for="locale">locale</label>
        <select v-model="locale">
            <option>en</option>
            <option>ja</option>
        </select>
        <p>message: {{ $t("hello") }}</p> -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <!-- Header -->
        
        <section class="header index">
            <a class="left" @click.prevent="$router.push('/record')">
                <img class="logo" src="@/assets/img/favicon.svg">
                <span class="primary">{{ displayUserPointBalance }}</span>
            </a>
            <div class="uide-page-name">縣政互動</div>
            <div class="right">
                <!-- <a class="icon iconButton" @click.prevent="barcodePopupOpen = true"> -->
                <a class="icon iconButton" @click.prevent="$router.push('/barcode')">
                    
                    <!-- <font-awesome-icon icon="fa-solid fa-barcode" size="xl"></font-awesome-icon> -->
                    <img  src="@/assets/img/barcode.svg">
                </a>
                <NotificationBell
                v-if="triggerCountUnread"
                @count-unread="countUnread = $event"
            />
            </div>
        </section>
        <!-- Main -->
        <section class="main service">
            <div class="uide-service-title">政府宣導</div>
            <div class="top flexH width between ">
                <a class="w-49" :href="donateInvoiceURL">
                    <img  class="w-full" src="@/assets/icon/receipt.svg">
                </a>
                <a 
                    class="w-49"
                    @click.prevent="$router.push('/service/on-site-activities')"
                >
                    <img class="w-full" src="@/assets/icon/checkin-v2.svg">
                </a>
            </div>
            <div class="uide-service-title">健康運動專區</div>
            <div class="uide-service-board-wrap">
                <div class="gap-5 flex justify-between">
                    <a href="https://elderlearning.yunlin.gov.tw/" target="_blank" class="uide-service-button-wrap">
                        <div><img src="@/assets/icon/uide/Frame1196.svg"/></div>
                        <div class="uide-service-button-text">樂齡健康學習平台</div>
                    </a>
                    <a href="https://yunlingo.healthygo.tw/" target="_blank" class="uide-service-button-wrap">
                        <div><img src="@/assets/icon/uide/Frame1197.svg"/></div>
                        <div class="uide-service-button-text">健康活動參與平台</div>
                    </a>
                    <a class="uide-service-button-wrap" @click.prevent="$router.push('/healthy-step')">
                        <div><img src="@/assets/icon/uide/Frame1198.svg"/></div>
                        <div class="uide-service-button-text">健康記步</div>
                    </a>
                </div>
                <div  class="gap-5 flex justify-between">
                    <a :href="isIOS ? 'https://apps.apple.com/us/app/雲林健康步道/id1628226822' : 'https://play.google.com/store/apps/details?id=com.lohasnet.lohastrail'" target="_blank" class="uide-service-button-wrap">
                        <div><img src="@/assets/icon/uide/Frame1212.svg"/></div>
                        <div class="uide-service-button-text">雲林健康步道</div>
                    </a>
                </div>
            </div>
            <div class="uide-service-title">縣民服務</div>
            <div class="uide-service-board-wrap">
                <div class="gap-5 flex justify-between">
                    <a class="uide-service-button-wrap" @click.prevent="$router.push('/digital-card')">
                        <div><img src="@/assets/icon/uide/Frame1199.svg"/></div>
                        <div class="uide-service-button-text">數位圖書證</div>
                    </a>
                    <a class="uide-service-button-wrap" @click.prevent="$router.push('/parking-coupons')">
                        <div><img src="@/assets/icon/uide/Frame1200.svg"/></div>
                        <div class="uide-service-button-text">停車繳費</div>
                    </a>
                    <a class="uide-service-button-wrap">
                        <div><img src="@/assets/icon/uide/Frame1201.svg"/></div>
                        <div class="uide-service-button-text">循環餐具租借與任務</div>
                    </a>
                </div>
                <div class="gap-5 flex justify-between">
                    <a href="https://eservice.yunlin.gov.tw/" target="_blank" class="uide-service-button-wrap">
                        <div><img src="@/assets/icon/uide/Frame1202.svg"/></div>
                        <div class="uide-service-button-text">線上申辦</div>
                    </a>
                    <a class="uide-service-button-wrap">
                        <div><img src="@/assets/icon/uide/Frame1204.svg"/></div>
                        <div class="uide-service-button-text">相關推薦</div>
                    </a>
                    <a :href="isIOS ? 'https://apps.apple.com/tw/app/%E9%9B%B2%E6%9E%97%E7%B8%A3%E6%99%BA%E6%85%A7%E6%A0%A1%E5%9C%92%E8%A6%AA%E5%B8%AB%E7%94%9F%E5%B9%B3%E5%8F%B03-0/id1554051196 ' : 'https://play.google.com/store/apps/details?id=tw.com.schoolsoft.app.scss12.yunlinschapp&pcampaignid=web_share'" class="uide-service-button-wrap">
                        <div><img width="48" height="49" src="@/assets/icon/teacher&student.svg"/></div>
                        <div class="uide-service-button-text">親師生平台</div>
                    </a>
                </div>
            </div>
            <div class="uide-service-title">鄉土語言專區</div>
            <div class="uide-service-board-wrap">
                
                <div class="gap-5 flex ">
                    <a href="https://youtube.com/playlist?list=PLTX2kqU2zABA04XSJXmB5hp799AXnW0r7&si=gEaOql4oHBq4fiL6" target="_blank" class="uide-service-button-wrap">
                        <div><img src="@/assets/icon/uide/Frame1207.svg"/></div>
                        <div class="uide-service-button-text">詔安客語</div>
                    </a>
                    <a href="https://web.klokah.tw/" target="_blank" class="uide-service-button-wrap">
                        <div><img src="@/assets/icon/uide/Frame1208.svg"/></div>
                        <div class="uide-service-button-text">原住民族語</div>
                    </a>
                </div>
            </div>                                   
        </section>
    </div>
</template>
<script>
import { mapState } from "vuex";
import NotificationBell from '@/components/NotificationBell.vue';

export default {
    name: "Service",
    components: {
        NotificationBell,
    },
    data() {
        // this.$i18n.locale = "en";
        return { 
            // locale: "en",
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            donateInvoiceURL: process.env.VUE_APP_DONATE_INVOICE_URL,
            triggerCountUnread:false,
            campaigns: [],
        };
    },
    activated() {
        this.updateUserPoints();
        this.triggerCountUnread = true;
    },
    deactivated() {
        this.noQAPopupClose();
        this.getPointPopupClose();
        this.barcodePopupOpen = false;
        this.triggerCountUnread = false;
        // this.closeIntro();
    },
    mounted() {
        this.getCampaigns();
    },
    computed:{
        ...mapState(["user"]),
        qnaCampaigns() {
          return this.campaigns.filter(({campaignPrototype}) => campaignPrototype === 'qna');
        },
        voteCampaigns() {
          return this.campaigns.filter(({campaignPrototype}) => campaignPrototype === 'vote');
        },
        questionnaireCampaigns() {
          return this.campaigns.filter(({campaignPrototype}) => campaignPrototype === 'questionnaire');
        },
        numberOfQna() {
          return this.qnaCampaigns.length;  
        },
        numberOfVote() {
          return this.voteCampaigns.length;  
        },
        numberOfQuestionnaire() {
          return this.questionnaireCampaigns.length;  
        },
        displayedQna() {
            return this.qnaCampaigns.slice(0, 3);
        },
        displayedVote() {
            return this.voteCampaigns.slice(0, 3);
        },
        displayedQuestionnaire() {
            return this.questionnaireCampaigns.slice(0, 3);
        },
        displayUserPointBalance() {
            return this.userPoints && this.userPoints.pointBalance
                ? this.userPoints.pointBalance
                : 0;
        },
        isIOS() {
            return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        }
    },
    watch: {
        locale(val) {
            this.$i18n.locale = val;
        }
    },
    methods: {
        getCampaignAPI() {
            let config = {
                url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/participations/${this.user.userId}?includeNonJoined=true&publisherType=municipal`,
                method: "GET"
            };
            return this.$http(config);
        },
        getCampaigns() {
            return (
                this.getCampaignAPI()
                    .then((res) => {
                        this.campaigns = res.data.campaigns.filter(
                            ({ campaignVisibility }) => campaignVisibility == 'public'
                        );
                        this.sortCampaigns();
                    })
            );
        },
        sortCampaigns() {
            let incompleteCampaigns = this.campaigns.filter(c => c.completeness == 'incomplete');
            let notParticipatingCampaigns = this.campaigns.filter(c => c.completeness == 'not-participating');
            let completedCampaigns = this.campaigns.filter(c => c.completeness == 'completed');

            function sortByTsDesc(b, a) {
                return a.campaignStartTime - b.campaignStartTime;
            }
            incompleteCampaigns.sort(sortByTsDesc);
            notParticipatingCampaigns.sort(sortByTsDesc);
            completedCampaigns.sort(sortByTsDesc);
            this.campaigns = incompleteCampaigns.concat(notParticipatingCampaigns).concat(completedCampaigns);
        },    
    }
};
</script>

<style scoped>
a {
    color: black;
}
</style>

<i18n>
{
  "en": {
    "hello": "hello world!666"
  },
  "ja": {
    "hello": "こんにちは、世界！666"
  }
}
</i18n>